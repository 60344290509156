import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { SubmitBtn } from "../Styles/InputStyles";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditExtraChargs = ({
  selectedData,
  addExtraCharges,
  setAddExtraCharges,
  refetch,
}) => {
  const [charges, setCharges] = useState(undefined);
  const [error, setError] = useState(undefined);
  const user = useSelector((state) => state.UserReducer.user);
  const [repetation,setRepetation]=useState(selectedData?.repetitionType || "1")
  const submitFunction = async () => {
    if (!charges) setError("Please Enter a amount");
    else if (charges.match(/^[0-9]+$/) === null)
      setError("Please Enter only numbers");
    else {
      if (charges) {
        setAddExtraCharges(false);

        setError(undefined);
        const body = new FormData();
        body.append("id", selectedData.id);
        body.append("amount", charges);
        body.append("rep",repetation)

        await axios
          .post(`${Config.apiUrl}/editextracharge`, body, {
            headers: {
              authorization: "Bearer " + user.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success(res.data.msg, { hideProgressBar: false });
            console.log("should refetch now");
            refetch();
          })
          .catch((err) => {
            toast.error(err.response.statusText, { hideProgressBar: false });
          });
      }
    }
  };
  
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddExtraCharges}>
      <Wrapper>
        <Title>Edit Charges</Title>
        <hr className="text-gray-600" />
        <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
          You may Edit Extra Charges for the{" "}
          <span className="text-primary-color">{selectedData?.name}</span>
        </p>
        <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
          Amount
        </p>
        <input
          type="text"
          placeholder={`Previous Charge was ${selectedData.amount}₹`}
          className="border rounded bg-gray-200 text-gray-800"
          onChange={(e) => setCharges(e.target.value)}
        />
        {error && (
          <p className="text-sm md:text-lg lg:text-base text-rose-400">
            {error}
          </p>
        )}
        <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-3 mb-1">
        Repetition
        </p>
        <select value={repetation} onChange={(e)=>setRepetation(e.target.value)} className="border">
          <option value="2" disabled={selectedData?.name==="Registration"?true:false}>Monthly</option>
          <option value="1">Once</option>
        </select>
        <BtnWrapper onClick={() => submitFunction()}>
          <SubmitBtn>Submit</SubmitBtn>
        </BtnWrapper>
      </Wrapper>
    </Model>
  );
};
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default EditExtraChargs;
