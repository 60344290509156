import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { SubmitBtn } from "../Styles/InputStyles";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddFloor = (setViewAddFloors, selectedData) => {
  const user=useSelector(state=>state.UserReducer.user)
  const InitialValue = {
    floorCount: "",
  };

  const onSuccessToast=(value)=>{
    toast.success(value,{hideProgressBar:false})
  }
  const onErrorToast=(value)=>{
    toast.warn(value,{hideProgressBar:false})
  }
  const addFloorSchema = Yup.object() .shape({
    floorCount: Yup.string().required("Enter the floor count"),
  });
  const addFloorFormik = useFormik({
    initialValues:InitialValue,
    validationSchema: addFloorSchema,
    onSubmit: (values) => {
      postAddFloors(values)
    },
  });
  const postAddFloors=async(values)=>{
    const body=new FormData()
    body.append("propId",setViewAddFloors.selectedData.id)
    body.append("numFloors",values.floorCount)

    let response=await axios.post(`${Config.apiUrl}/addfloors`,body,{
      headers:{
        authorization:"Bearer "+user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      }
    }).then(res=>{
      console.log(res)
      onSuccessToast(res?.data?.msg)
    }).catch(err=>{
      onErrorToast(err.data.msg)
    })

    setViewAddFloors.setViewAddFloors(false)
    return response
  }
  
  return (
    <Model
      width={`w-11/12 max-w-lg`}
      setOpenModel={setViewAddFloors.setViewAddFloors}
    >
      <Title>Add Floors</Title>
      <hr className="text-gray-600" />
      <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium mt-3">
        Number of Floors
      </h2>
      <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
        Set number of floors to be increased or decrease in the property
      </p>
      <form onSubmit={addFloorFormik.handleSubmit}>
        <div className="h-10 w-40 border border-gray-300 bg-gray-100 text-gray-800 rounded-md">
          <input
            type="number"
            name="floorCount"
            // value={addFloorFormik.values.floorCount}
            onChange={addFloorFormik.handleChange}
            onBlur={addFloorFormik.handleBlur}
          />
        </div>
        <div className="text-rose-500">
          {addFloorFormik.touched.floorCount &&
            addFloorFormik.errors.floorCount}
        </div>
        <BtnWrapper>
          <input
            type="submit"
            value="Submit"
            className="text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-primary-color hover:bg-hover-color text-white rounded-md shadow-md`;
"
          />
        </BtnWrapper>
      </form>
    </Model>
  );
};
const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default AddFloor;
