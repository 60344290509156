import React, { useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading";

import {
  FieldWrapper,
  InputGroup,
  Label,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import Select from "@mui/material/Select";
import Table from "../../Components/ExtraCharges/Table";
import EditExtraChargs from "../../Components/ExtraCharges/EditExtraChargs";
import { AddExtraCharges } from "../../Components/Properties/AddExtraCharges";

const ExtraCharges = () => {
  const { clientId, propId } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("charges");
  const [addExtraCharges, setAddExtraCharges] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [extraCharges, setExtraCharges] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchExtraCharges = async () => {
    if (clientId) {
      const formData = new FormData();
      formData.append("clientId", clientId);
      formData.append("propId", propId);
      let res = axios.post(`${Config.apiUrl}/getextracharges`, formData, {
        headers: {
          authorization: "Bearer " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      return await res;
    }
  };

  const {
    isLoading: isLoading,
    error,
    data,
    refetch,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["extraCharges"],
    queryFn: () => fetchExtraCharges(),
    isLoading: () => console.log("loading"),
  });
  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    // getOwnersMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };
  const getReportError = (res) => {
    toast.error(error?.res?.data?.msg || "Error exporting report");
  };
  const handleButtonClick = () => {
    // getReportMutate();
  };
  
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Extra Charges "}
                <span className="text-primary-color">
                  ( {location?.state?.name} )
                </span>
              </Title>
              {/* <Underline /> */}
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setExtraCharges(true)}
            >
              Add Charges
            </button>
          </div>

          {addExtraCharges && (
            <EditExtraChargs
              selectedData={selectedData}
              addExtraCharges={addExtraCharges}
              setAddExtraCharges={setAddExtraCharges}
              refetch={refetch}
            />
          )}

          {extraCharges && (
            <AddExtraCharges
              setAddCharges={setExtraCharges}
              data={location?.state?.apiData}
            />
          )}

          {error && <div>UNDER MAINTAINANCE</div>}
          <TableWrapper>
            {isRefetching && <Loading />}
            {isSuccess &&
              !isRefetching &&
              data?.data?.msg !== "No data found" && (
                <Table
                  ApiData={data?.data?.data}
                  userType={user.userType}
                  setAddExtraCharges={setAddExtraCharges}
                  setSelectedData={setSelectedData}
                />
              )}
          </TableWrapper>
          {data?.data?.msg === "No data found" && isSuccess && !isLoading && (
            <div>
              <h3 className="text-2xl text-gray-600">{data?.data?.msg}</h3>
            </div>
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;

export default ExtraCharges;
