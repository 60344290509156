import React from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SubmitBtn } from "../Styles/InputStyles";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import { toast } from "react-toastify";

export const AddExtraCharges = ({ setAddCharges, data }) => {
  const user = useSelector((state) => state.UserReducer.user);

  const ExtraChargesSchema = Yup.object().shape({
    chargesType: Yup.number()
      .min(1)
      .max(5)
      .required("Please select charges types"),
    amount: Yup.string()
      .required("Please specify the amount")
      .matches("^[0-9]*$", "Only Numbers are allowed"),
    repetation: Yup.number()
      .min(1)
      .max(2)
      .required("Please specify the amount to be one time or monthly")
      .when("chargesType", (chargesType, schema) => {
        if (chargesType[0] === 1 || chargesType[0] === 3) {
          return schema.oneOf(
            [2],
            "Only Monthly Charges are applicable on " +
              EXTRA_CHARGES_TYPE[chargesType[0]]
          );
        } else if (chargesType[0] === 2) {
          return schema.oneOf(
            [1],
            "Only One time Charges are applicable on Registration"
          );
        }

        return schema;
      }),
    linkedProp: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("Please select the linked property"),
  });

  const InitialValues = {
    chargesType: "",
    amount: "",
    repetation: "",
    linkedProp: "",
  };

  const postAddExtraCharges = async (values) => {
    
    values=Object.assign({"clientId":data[0].clientId},values)
    values=Object.assign({"name": EXTRA_CHARGES_TYPE[values.chargesType]},values)
    
    const formData=new FormData()

    const keys=Object.keys(values)
    console.log(values["linkedProp"])
    keys.map(i=>{
      if(i!=="linkedProp")
        formData.append(i,values[i])
      else
        formData.append(i,JSON.stringify(values[i]))
    })

    await axios
      .post(`${Config.apiUrl}/addextracharges`, formData, {
        headers: {
          authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((res) => toast.success(res.data.msg,{hideProgressBar:false}))
      .catch((err) => toast.warn(err.response.data.msg,{hideProgressBar:false}));
      setAddCharges(null)
  };

  const extraChargesFormik = useFormik({
    initialValues: InitialValues,
    validationSchema: ExtraChargesSchema,
    onSubmit: (values) => {
      postAddExtraCharges(values);
    },
  });

  const EXTRA_CHARGES_TYPE = [
    "Please Select Charges Type",
    "Electricity",
    "Registration",
    "Tech Charges",
    "Maintenance",
    "Others",
  ];

  const REPETATION = ["Please Select repetation amount", "Once", "Monthly"];
  
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddCharges}>
      <Wrapper>
        <Title>Add Charges</Title>
        <hr className="text-gray-600" />

        <form onSubmit={extraChargesFormik.handleSubmit} className="my-2">
          <p className="text-xs md:text-sm lg:text-base text-gray-600 my-1">
            Charge Type
          </p>
          <select
            onChange={extraChargesFormik.handleChange}
            onBlur={extraChargesFormik.handleBlur}
            value={extraChargesFormik.values.chargesType}
            name="chargesType"
            className="border rounded"
          >
            {EXTRA_CHARGES_TYPE.map((item, key) => (
              <option key={key} value={key}>
                {item}
              </option>
            ))}
          </select>
          <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
            {extraChargesFormik.touched.chargesType &&
              extraChargesFormik.errors.chargesType}
          </div>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
            Amount
          </p>
          <input
            type="text"
            value={extraChargesFormik.values.amount}
            name="amount"
            onChange={extraChargesFormik.handleChange}
            onBlur={extraChargesFormik.handleBlur}
            className="border rounded"
            placeholder={extraChargesFormik.values.chargesType==="1"?"Add electricity Unit ":"Add Amout"}
          />
          <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
            {extraChargesFormik.touched.amount &&
              extraChargesFormik.errors.amount}
          </div>

          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
            Repetation
          </p>
          <select
            onChange={extraChargesFormik.handleChange}
            onBlur={extraChargesFormik.handleBlur}
            value={extraChargesFormik.values.repetation}
            name="repetation"
            className="border rounded"
          >
            {REPETATION.map((item, key) => (
              <option key={key} value={key}>
                {item}
              </option>
            ))}
          </select>
          <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
            {extraChargesFormik.touched.repetation &&
              extraChargesFormik.errors.repetation}
          </div>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
            Property name
          </p>
          <div className="flex flex-col text-sm justify-start items-start max-w-fit space-y-2 pb-2">
            {data.map((item, key) => (
              <div className="flex justify-start items-center space-x-1">
                <input
                  key={key}
                  type="checkbox"
                  name="linkedProp"
                  value={item.id}
                  onChange={extraChargesFormik.handleChange}
                  onBlur={extraChargesFormik.handleBlur}
                />{" "}
                <p className="text-cs md:text-sm lg:text-base text-gray-600 min-w-fit">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
            {extraChargesFormik.touched.linkedProp &&
              extraChargesFormik.errors.linkedProp}
          </div>
          <SubmitBtn type="submit">Submit</SubmitBtn>
        </form>
      </Wrapper>
    </Model>
  );
};
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
