import axios from "axios";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Config from "../../Config";

const Cards = ({ data, setAadharValue, aadharValue ,user,refetch}) => {
  useEffect(() => {
    setAadharValue(data?.status);
  }, data);

  const getType = (value) => {
    if (value == 1) return "Aadhar Card";
  };
  const postChangeData=async (value)=>{
    console.log(aadharValue)
    const formData=new FormData()
    
    formData.append("status",value)
    formData.append("id",data?.id)

    await axios.post(`${Config.apiUrl}/editsystemconfig`, formData, {
        headers: {
          authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }).then(res=>{
        toast.success(res.data.msg,{hideProgressBar:false})
      }).catch(err=>{
        toast.error(err.response.statusText,{hideProgressBar:false})
      });
    
      refetch()
  
    
  }
  return (
    <div className=" border rounded shadow-lg">
      <div className="m-4 flex justify-start items-center gap-5 max-md:flex-wrap">
        <div className="text-2xl text-primary-color  ">{data.name}</div>
        <div className="text-gray-600 text-lg">{getType(data.type)}</div>
        <div className="w-1/2 flex justify-center items-center">
          <select
            onChange={(e) => {
              postChangeData(e.target.value)
            }}
            value={aadharValue}
            className="border rounded w-1/2 max-md:w-full border-primary-color"
          >
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </select>
        </div>
        <div className="text-sm text-gray-600">
        updated at <br />
          <span className="text-primary-color">{data.updatedAt}</span>
        </div>
      </div>
    </div>
  );
};

export default Cards;
