import React, { useEffect, useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import ExcelDropzone from "./ExcelDropzone";
import { SubmitBtn } from "../Styles/InputStyles";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddExcelFile = ({ selectedData, setViewExcelFile }) => {
  const [excelData, setExcelData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const user = useSelector((state) => state.UserReducer.user);

  useEffect(() => {
    if (excelData) {
      let isError = false;
      excelData.map((i) => {
        if (i.type !== "text/csv") {
          setError("Please enter correct file format (.csv)");
          isError = true;
          setExcelData(undefined);
          return;
        }
      });
      if (!isError) {
        setError(false);
      }
    }
  }, [error, excelData]);

  const onSuccesToast = (data) =>
    toast.success(data, { hideProgressBar: false });

  const onErrorToast = (data) => toast.warn(data, { hideProgressBar: false });

  const submitExcel = async () => {
    if (excelData) {
      const body = new FormData();
      body.append("filename", excelData[0]);
      body.append("clientId", selectedData.clientId);
      body.append("propId", selectedData.id);
      let response = await axios
        .post(`${Config.apiUrl}/uploadtenantfile`, body, {
          headers: {
            authorization: "Bearer " + user.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data.msg);
          setViewExcelFile(false);
          onSuccesToast(res.data.msg);
        })
        .catch((err) => {
          setViewExcelFile(false);
          onErrorToast(err.data.msg);
        });
      setError(undefined);
    } else {
      setError("Please upload a .csv file");
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewExcelFile}>
      <Title className="text-center">Tenant List</Title>
      <hr className="text-gray-600" />
      <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium mt-3">
        CSV File
      </h2>
      <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
        Please make sure to provide correct csv file format
      </p>
      <div className="bg-gray-200 p-3 rounded">
        <h3 className="text-sm font-semibold mb-2 text-gray-600">
          Download sample Dataset
        </h3>
        <div className="flex">
          <div className="text-sm text-gray-600">
            You can download the attached example and use them as a starting
            point for your own file
          </div>

          <SubmitBtn className="bg-white rounded p-2 hover:bg-gray-300 text-gray-600 hover:text-white" onClick={()=>{
            let a=document.createElement("a")
            a.setAttribute("href","/TenantRecords.csv")
            a.setAttribute("download","Tenant Records")
            a.click()
          }}>
            {/* <a
              href="/TenantRecords.csv"
              className="text-sm text-gray-600"
              download
            >
              Download
            </a> */}
            Download
          </SubmitBtn>
        </div>
      </div>
      <ExcelDropzone setExcelData={setExcelData} />
      {excelData && (
        <div className="text-green-400 mt-2">
          {excelData[0].path} Uploaded Successfully.
        </div>
      )}
      {error && <div className="text-rose-500">{error}</div>}
      <BtnWrapper
        onClick={() => {
          submitExcel();
        }}
      >
        <SubmitBtn>Submit</SubmitBtn>
      </BtnWrapper>
    </Model>
  );
};

const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default AddExcelFile;
