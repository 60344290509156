import React, { useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Cards from "../../Components/SystemConfig/Cards";
import Loading from "../../Components/Loading";
const SystemConfig = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [aadharValue, setAadharValue] = useState(undefined);
  const { t, i18n } = useTranslation();

  const fetchDetails = async () => {
    let res = await axios.post(`${Config.apiUrl}/getsystemconfig`, "", {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

    return await res.data;
  };

  const { data, isLoading, isSuccess, isError,refetch,isRefetching } = useQuery({
    queryKey: ["systemConfig"],
    queryFn: () => fetchDetails(),
  });
  console.log(data);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("System Config")}</Title>
              <Underline />
            </div>
          </div>
          {isLoading && <Loading />}
          {!isLoading && isError && <div>Internal Server Error !!</div>}
          {isRefetching && <Loading/>}
          {!isLoading && !isError && isSuccess && !isRefetching &&(
            <div className="mt-8">
              {
                <Cards
                  data={data?.data[0]}
                  setAadharValue={setAadharValue}
                  aadharValue={aadharValue}
                  user={user}
                  refetch={refetch}
                />
              }
            </div>
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default SystemConfig;
