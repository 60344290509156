import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const TenantForm = ({ setTenantData, setActiveStep, selectedBed,tenantData }) => {
  const occupationArray = ["Select Value", "Professional", "Student"];

  const IntialTenantValues = {
    name: tenantData?.name,
    mobile: tenantData?.mobile,
    gender: tenantData?.gender,
    occupation: tenantData?.occupation,
  };
  
  const TenantFormSchema = Yup.object().shape({
    name: Yup.string().required("Enter Name"),
    mobile: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter Correct Phone Number")
      .max(10, "Enter Correct Phone Number"),
    gender: Yup.string().required("Select Gender"),
    occupation: Yup.string().required("Select Occupation"),
  });

  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });
  const tenantFormik = useFormik({
    initialValues: IntialTenantValues,
    validationSchema: TenantFormSchema,
    onSubmit: (values) => {
      if (selectedBed) {
        setTenantData(values);
        setActiveStep(1);
      } else {
        toastSelectBed();
        return;
      }
    },
  });

  return (
    <form
      onSubmit={tenantFormik.handleSubmit}
      className="flex items-end flex-wrap gap-3 w-full"
      id="tenant-form"
    >
      <div className="w-full flex gap-5 mt-8 max-sm:flex-wrap">
        {/* TENANT NAME */}
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="floor" className="text text-primary-color">
            Tenant Name
          </lable>
          <input
            type="text"
            name="name"
            id="tenantName"
            className="border border-primary-color rounded w-full  "
            placeholder="Enter Full Name"
            onChange={tenantFormik.handleChange}
            onBlur={tenantFormik.handleBlur}
            value={tenantFormik.values.name}
          />
          <div className="text-rose-500">
            {tenantFormik.touched.name && tenantFormik.errors.name}
          </div>
        </div>
        {/* PHONE NUMBER */}
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="floor" className="  text-primary-color">
            Phone No.
          </lable>
          <input
            type="text"
            name="mobile"
            id="mobile"
            className="border border-primary-color rounded w-full  "
            placeholder="Enter Phone No. "
            onChange={tenantFormik.handleChange}
            onBlur={tenantFormik.handleBlur}
            value={tenantFormik.values.mobile}
          />
          <div className="text-rose-500">
            {tenantFormik.touched.mobile && tenantFormik.errors.mobile}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5 max-sm:flex-wrap">
        {/* GENDER */}
        <div className="flex-row justify-start items-center w-full gap-5">
          <label className="  text-primary-color">Gender</label>
          <div className="flex items-center gap-2">
            <div className="flex gap-2">
              <input
                type="radio"
                name="gender"
                value="1"
                onChange={tenantFormik.handleChange}
                onBlur={tenantFormik.handleBlur}
                // checked={tenantData?.gender!==undefined && tenantFormik.values.gender==="1"?true:false}
                checked = {tenantFormik.values.gender === '1'}
              />{" "}
              <label>Male</label>
            </div>
            <div className="flex gap-2">
              <input
                type="radio"
                name="gender"
                value="2"
                onChange={tenantFormik.handleChange}
                onBlur={tenantFormik.handleBlur}
                //checked={tenantData?.gender!==undefined && tenantFormik.values.gender==="2"?true:false}
                checked = {tenantFormik.values.gender === '2'}
              />{" "}
              <label>Female</label>
            </div>
          </div>
          <div className="text-rose-500">
            {tenantFormik.touched.gender && tenantFormik.errors.gender}
          </div>
        </div>
        {/* OCCUPATION */}
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="occupation" className="  text-primary-color">
            Occupation
          </lable>
          <select
            className="  border-primary-color rounded border w-full"
            name="occupation"
            onChange={tenantFormik.handleChange}
            onBlur={tenantFormik.handleBlur}
            value={tenantFormik.values.occupation}
          >
            {occupationArray.map((item, key) => (
              <option key={key} value={item}>
                {item}
              </option>
            ))}
          </select>
          <div className="text-rose-500">
            {tenantFormik.touched.occupation && tenantFormik.errors.occupation}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5"></div>
    </form>
  );
};

export default TenantForm;
