import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import Config, { PaginationLimit } from "../../Config";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import moment from "moment";
import { IoMdMore, IoIosCreate } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";


const Table = ({ ApiData,userType,setAddExtraCharges,setSelectedData }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const [previousId, setPreviousId] = useState(null);
  
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      console.log("Button clicked, ID:", id);
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        console.log("Previous open menu ID:", prevId);
        return prevId === id ? null : id;
      });
    };

    const editAddExtraCharges=(item)=>{
      console.log(item)
      setAddExtraCharges(true)
      setSelectedData(item)
      setOpenActionMenu(null)
    }

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType!=Config.userType.MARKETING && (
              <MenuItem title="addExtraCharges" onClick={()=>editAddExtraCharges(item)}> 
              {/* onClick={() => edit(item)}> */}
                <MdModeEdit className="text-gray-700 mr-2" /> Edit 
              </MenuItem>)}
            
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: item.name,
        date: moment(item.createdAt).format("DD-MM-YYYY"),
        charges: "₹"+item.amount,
        
        // status: getStatus(item.status),
        action: ActionBtns(item),
        repetation:item.repetitionType==1?"Once":"Monthly",
      }));
      setData(tempData);
    //   console.log(tempData)
    }
  }, [ApiData, openActionMenu]);

  const columns = useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Charges For",
          accessor: "name",
        },
        {
          Header: "Amount",
          accessor: "charges",
        },
      ];
    }

    // Columns for larger screens
    return [
      {
        Header: "Charges For",
        accessor: "name",
      },
      {
        Header: "Amount",
        accessor: "charges",
      },
      {
        Header: "Due From",
        accessor: "date",
      },
      {
        Header:"Repetition",
        accessor:"repetation"
      },
    //   {
    //     Header: "Created At",
    //     accessor: "date",
    //   },
    //   {
    //     Header: "Status",
    //     accessor: "status",
    //   },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  return(<>
    <CustomTable {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    <img
                      src={Images.Arrow}
                      alt="down arrow"
                      className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                    />
                  ) : (
                    ""
                  )}
                </span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
        {page.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <React.Fragment key={rowIndex}>
              <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      onClick={() => {
                        if (cellIndex === 0 || cellIndex === 1 && window.innerWidth < 768) {
                          toggleRow(rowIndex);
                        }
                      }}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
              {selectedRow === rowIndex && window.innerWidth < 768 && (
                <Tr>
                  <Td colSpan={columns.length}>
                    <ul className="flex flex-col gap-2">
                      <li>
                        <strong>City:</strong> {data[rowIndex].city}
                      </li>
                      <li>
                        <strong>Created At:</strong> {data[rowIndex].date}
                      </li>
                      <li className="flex gap-2">
                        <strong>Status:</strong> {data[rowIndex].status}
                      </li>
                      <li>
                        <strong>Action:</strong> {data[rowIndex].action}
                      </li>
                    </ul>
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          );
        })}
      </Tbody>
    </CustomTable>
    {ApiData?.length > 0 && (
      <PaginationWrapper>
        <div className="px-2">
          Page{" "}
          <em>
            {pageIndex + 1} of {pageOptions.length}
          </em>
        </div>
        <div className="flex gap-1">
          <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
            <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
          </PrevBtn>
          <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
            <img
              src={Images.Arrow}
              alt="arrow"
              className="w-2 rotate-180 opacity-75 "
            />
          </NextBtn>
        </div>
      </PaginationWrapper>
    )}
  </>);
};
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
// const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? 'border-b' : '')}`;
const Tr = tw.tr`${(props) => (props.hasData ? "border-b" : "")} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
// const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Active = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const Verified = tw.div`bg-teal-100 text-teal-700 text-xs border-teal-300 border w-24 h-7 flex items-center justify-center rounded`;
const Unverified = tw.div`bg-rose-100 text-rose-700 text-xs border-rose-300 border w-24 h-7 flex items-center justify-center rounded`;
const Enrolled = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;

export default Table;
