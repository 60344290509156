import {React, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Login from "./Pages/Login";
import DashBoardNew from "./Pages/Admin/DashBoardNew";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPage from "./Pages/RegisterPage";
import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import "./i18";
import { Remove_User } from "./Redux/actions";
import NotfoundPage from "./Components/NotfoundPage";
import Owners from "./Pages/Admin/Owners";
import Tenants from "./Pages/Admin/Tenants";
import Properties from "./Pages/Admin/Properties";
import AllProperties from "./Pages/Admin/AllProperties";
import Occupancy from "./Pages/Admin/Occupancy";
import PropComplaints from "./Pages/Admin/PropComplaints";
import TenantsComplaints from "./Pages/Admin/TenantsComplaints";
import LatestComplaints from "./Pages/Admin/LatestComplaints";
import Transactions from "./Pages/Admin/Transactions";
import Prospects from "./Pages/Admin/Prospects";
import RoomOptions from "./Pages/Admin/RoomOptions";
import Floors from "./Pages/Admin/Floors";
import OccupiedRooms from "./Pages/Admin/OccupiedRooms";
import Beds from "./Pages/Admin/Beds";
import AddTenant from "./Pages/Admin/AddTenant";
import ExtraCharges from "./Pages/Admin/ExtraCharges";
import SystemConfig from "./Pages/Admin/SystemConfig";

function App() {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };
  useEffect(() => isSessionExpired(), []);
  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />
          <Route path="*" index element={<Login />} />
        </Routes>
      </> 
    );
  return (
    <> 
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
             <Route path="/login" element={<Login />} /> 
            <Route path="*" element={<NotfoundPage />} /> 
            <Route path="/" element={<Owners/>} /> 
            <Route path="/dashboard" element={<DashBoardNew/>} /> 
            <Route path="/owners" element={<Owners/>} /> 
            <Route path="/tenants" element={<Tenants/>} /> 
            <Route path="/properties/:id" element={<Properties />}/>  
            <Route path="/occupancy/:id" element={<Occupancy/>} /> 
            <Route path="/complaints/:id/:clientId" element={<PropComplaints/>}/>
            <Route path="/tenantscomplaints/:id" element={<TenantsComplaints/>}/>
            <Route path="/complaints" element={<LatestComplaints/>}/>
            <Route path="/transactions/:tenantId" element={<Transactions/>}/> 
            {/* <Route path="/Room" element={<Room/>}/>  */}
            <Route path="prospects" element={<Prospects/>}/> 
            <Route path="allproperties" element={<AllProperties/>}/>
            <Route path="roomoptions/:id" element={<RoomOptions/>}/> 
            <Route path="floors/:id" element={<Floors/>}/>  
            <Route path="beds/:roomId" element={<Beds/>} />  
            <Route path="occupiedroom/:propId/:roomId" element={<OccupiedRooms />} />
            <Route path="addtenant/:propId/:roomId/:clientId" element={<AddTenant/>}/>
            <Route path="extracharges/:clientId/:propId" element={<ExtraCharges/>}/>
            <Route path="/systemconfig" element={<SystemConfig/>}/>
      </Routes>
    </>
  );
}  
export default App;