import React, { useState } from "react";
import tw from "tailwind-styled-components";
import Loading from "../Loading";
import Config from "../../Config";
import { SubmitBtn } from "../Styles/InputStyles";
import Model from "../Model";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
const RoomDoesNotContainsOptions = ({
  setRoomUser,
  selectedData,
  roomEntryLoading,
  roomOptions,
}) => {
  const [selectedRoomOption, setSelectedRoomOption] = useState(0);

  const user = useSelector((state) => state.UserReducer.user);
  const showToastError = () => {
    toast.error("Data Updated Fail !! ", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
    });
  };

  const showToastErrorMessage = (msg) => {
    toast.error(msg, {
      hideProgressBar: false,
    });
  };
  const showToastSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
    });
  };

  const submitFunction = async () => {
    const body = new FormData();

    if (
      selectedData.roomId !== undefined &&
      selectedRoomOption !== undefined &&
      roomOptions.length
    ) {
      body.append("roomId", selectedData.roomId);
      body.append("optionId", roomOptions[selectedRoomOption].id);

      let response = await axios.post(
        `${Config.apiUrl}/updateroomoptionid`,
        body,
        {
          headers: {
            authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      setRoomUser(false);

      response.statusText === "OK"
        ? showToastSuccess(response.data.msg)
        : showToastErrorMessage(response.data.msg);
    } else {
      showToastError();
      setRoomUser(false);
    }
  };

  return (
    <Model width={`w-[400px]`} setOpenModel={setRoomUser}>
      <Title>
        Room Number{" "}
        <span className="text-primary-color"> {selectedData?.roomNum} </span>
      </Title>
      
      {/* <Title>Room Options </Title> */}
      { roomOptions.length>0 && (
        <Wrapper>
          <p
            htmlFor="name"
            className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
          >
            Room Type
          </p>

          {roomEntryLoading && <Loading />}
          {roomOptions.length >= 0 && !roomEntryLoading && (
            <select
              className=" min-w-full border-2 rounded-md border-primary-color "
              onChange={(e) => {
                setSelectedRoomOption(e.target.value);
              }}
            >
              {roomOptions.length >= 0 ? (
                roomOptions.map((item, key) => {
                  return (
                    <option key={key} value={key}>
                      {item.name}
                    </option>
                  );
                })
              ) : (
                <option>Room Options not available !!!</option>
              )}
            </select>
          )}

          {roomOptions.length >= 0 && !roomEntryLoading && (
            <div>
              <div className="flex flex-wrap gap-2 mt-6">
                <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
                  Rent: ₹{roomOptions[selectedRoomOption]?.rent}
                </p>
                <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
                  Type:{" "}
                  {
                    Config.roomTypeMapping[
                      roomOptions[selectedRoomOption]?.type
                    ]
                  }
                </p>
              </div>
              <div className="flex flex-wrap gap-2 mt-6">
                {roomOptions[selectedRoomOption]?.amenities
                  ?.split(",")
                  .map((amenity, index) => (
                    <span
                      key={index}
                      className="bg-primary-color text-white rounded-full px-3 py-1 text-xs md:text-sm"
                    >
                      {amenity.trim()}
                    </span>
                  ))}
              </div>
            </div>
          )}
        </Wrapper>
      )}
      {(
        <BtnWrapper className="flex justify-center items-center">
          <SubmitBtn type="button" onClick={() => submitFunction()}>
            Submit
          </SubmitBtn>
        </BtnWrapper>
      )}
      {/* <ToastContainer /> */}
    </Model>
  );
};

const Wrapper = tw.div`mt-3 mb-3`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
const BtnWrapper = tw.div`w-full space-x-10 mt-8`;

export default RoomDoesNotContainsOptions;
